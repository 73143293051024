import React, { useRef } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import addPlayer from './store/actions';
import CreatePlayerForm from './CreatePlayerForm';
import createPlayerSchema from './validationSchema';
import Loader from '../../shared/displayComponents/loader/Loader';

const CreatePlayer = () => {
  const { isLoading } = useSelector(({ shared }) => shared);
  const multiselectEnglishRef = useRef();
  const multiselectPhysicalsRef = useRef();
  const multiselectAnalysisRef = useRef();
  const multiSelectLevelOfPlayRef = useRef();
  const positionRef = useRef();

  const { register, handleSubmit, errors, watch, control } = useForm({
    validationSchema: createPlayerSchema,
    defaultValues: {
      sport: 'Volleyball',
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'youtubeUrl',
  });

  const {
    fields: accomplishmentsFields,
    append: accomplishmentsAppend,
    remove: accomplishmentsRemove,
  } = useFieldArray({
    control,
    name: 'accomplishments',
  });

  const dispatch = useDispatch();
  const selectedSport = watch('sport');

  const createPlayer = ({ image, ...player }) => {
    const analysis = multiselectAnalysisRef.current.getSelectedItems();
    const physicals = multiselectPhysicalsRef.current.getSelectedItems();
    const englishSkills = multiselectEnglishRef.current.getSelectedItems();
    const levelOfPlay = multiSelectLevelOfPlayRef.current.getSelectedItems();
    const position = positionRef.current.getSelectedItems();
    const formData = {
      formValues: {
        ...player,
        englishSkills,
        physicals: physicals.length ? physicals.map(({ key }) => key) : [],
        analysis,
        levelOfPlay,
        position,
      },
      files: image,
    };
    dispatch(addPlayer(formData));
  };

  return isLoading ? (
    <Loader />
  ) : (
    <CreatePlayerForm
      fields={fields}
      append={append}
      remove={remove}
      multiselectAnalysisRef={multiselectAnalysisRef}
      multiselectPhysicalsRef={multiselectPhysicalsRef}
      multiselectEnglishRef={multiselectEnglishRef}
      multiSelectLevelOfPlayRef={multiSelectLevelOfPlayRef}
      selectedSport={selectedSport}
      register={register}
      handleSubmit={handleSubmit}
      errors={errors}
      createPlayer={createPlayer}
      accomplishmentsFields={accomplishmentsFields}
      accomplishmentsAppend={accomplishmentsAppend}
      accomplishmentsRemove={accomplishmentsRemove}
      positionRef={positionRef}
    />
  );
};

export default CreatePlayer;
