import * as Yup from 'yup';

export const createAccountSchema = Yup.object({
  universityEmail: Yup.string()
    .required()
    .label('University e-mail')
    .max(60)
    .email(),
  personalEmail: Yup.string()
    .label('Personal e-mail')
    .max(60)
    .email(),
  password: Yup.string()
    .max(15)
    .min(4)
    .required()
    .label('Password'),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Passwords must match',
  ),
  firstName: Yup.string()
    .required()
    .label('First name')
    .max(20)
    .min(3),
  lastName: Yup.string()
    .required()
    .label('Last name')
    .max(20)
    .min(3),
  jobTitle: Yup.string()
    .required()
    .label('Job title')
    .max(20)
    .min(2),
  cellPhone: Yup.string()
    .required()
    .label('Cell phone')
    .max(20)
    .min(3),
  schoolName: Yup.string()
    .required()
    .label('School name')
    .max(30)
    .min(3),
  sizeOfSchool: Yup.string()
    .required()
    .label('Size of school')
    .max(20)
    .min(3),
  teamDivision: Yup.string()
    .required()
    .min(2, 'Choose correct division')
    .label('Team division'),
  conferenceName: Yup.string()
    .label('Name of the conference')
    .max(20)
    .min(3),
  terms: Yup.boolean().oneOf([true], 'Confirm terms to create an account!'),
});

export const editAccountSchema = Yup.object({
  universityEmail: Yup.string()
    .required()
    .label('University e-mail')
    .max(60)
    .email(),
  personalEmail: Yup.string()
    .label('Personal e-mail')
    .max(60)
    .email(),
  firstName: Yup.string()
    .required()
    .label('First name')
    .max(20)
    .min(3),
  lastName: Yup.string()
    .required()
    .label('Last name')
    .max(20)
    .min(3),
  jobTitle: Yup.string()
    .required()
    .label('Job title')
    .max(20)
    .min(2),
  cellPhone: Yup.string()
    .required()
    .label('Cell phone')
    .max(20)
    .min(3),
  schoolName: Yup.string()
    .required()
    .label('School name')
    .max(30)
    .min(3),
  sizeOfSchool: Yup.string()
    .required()
    .label('Size of school')
    .max(20)
    .min(3),
  teamDivision: Yup.string()
    .required()
    .min(2, 'Choose correct division')
    .label('Team division'),
  conferenceName: Yup.string()
    .label('Name of the conference')
    .max(20)
    .min(3),
});
