import axios from 'axios';

import { loaderOff, loaderOn, toasterOn } from '../../../shared/store/actions';
import url from '../../../constants/config';

export const editPlayer = (
  playerId,
  { formValues, files },
  history,
) => async dispatch => {
  try {
    dispatch(loaderOn());
    const formData = new FormData();
    formData.append('values', JSON.stringify(formValues));
    formData.append('file', files[0]);
    const token = window.localStorage.getItem('authorization');
    await axios.put(`${url}/players/${playerId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(loaderOff());
    dispatch(
      toasterOn({ type: 'success', message: 'Player has been updated!' }),
    );
    history.push(`/available-players/${playerId}`);
  } catch (err) {
    dispatch(loaderOff());
    dispatch(
      toasterOn(
        err.response
          ? { message: err.response.data.message, type: 'failed' }
          : { message: err.message, type: 'failed' },
      ),
    );
  }
};
