import * as types from './types';

const initialState = {
  players: [],
  player: {},
  currentPage: 1,
  totalPages: 1,
  selectedFilters: {
    sport: 'Volleyball',
    gender: 'Female',
    graduationYear: 'Filter all',
    position: 'Filter all',
  },
};

const playerReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_ALL_PLAYERS:
      return {
        ...state,
        players: action.payload.players,
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
      };
    case types.FETCH_PLAYER:
      return {
        ...state,
        player: action.payload,
      };
    case types.DELETE_PLAYER:
      return {
        ...state,
        players: state.players.filter(player => player._id !== action.payload),
        player: {},
      };
    case types.ADD_FILTER:
      return {
        ...state,
        selectedFilters: action.payload,
      };
    case types.RESET_FILTERS:
      return {
        ...state,
        selectedFilters: initialState.selectedFilters,
      };
    case types.RESET_PAGINATION:
      return {
        ...state,
        currentPage: initialState.currentPage,
      };
    default:
      return state;
  }
};

export default playerReducer;
