import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';

import routes from '../../../routesDefinitions/routes';
import brandLogo from '../../../assets/brandLogo.png';
import Profile from '../profile/Profile';
import { logOut } from '../../store/authSlice';
import { resetFilters, resetPagination } from '../../../players/store/actions';

import './Menu.scss';

const Menu = ({
  setIsAuthorized,
  isAuthorized,
  isProfileFinished,
  userName,
  userRole,
}) => {
  const [expanded, setExpanded] = useState(false);
  const dispatch = useDispatch();
  const handleExpandChange = name => {
    if (name === 'Favorite Players' || name === 'Available Players') {
      dispatch(resetPagination());
      dispatch(resetFilters());
    }
    setExpanded(false);
  };

  const handleMenuChange = useCallback(() => {
    setExpanded(prevExpanded => !prevExpanded);
  }, []);

  const {
    players,
    faq,
    sampleProfile,
    aboutUs,
    createPlayer,
    createAccount,
    login,
    favorites,
    summerTrips,
  } = routes;

  const navigationLinks = [
    {
      name: favorites.name,
      link: favorites.link,
      shouldBeVisibleWithoutLogin: true,
    },
    {
      name: players.name,
      link: players.link,
      shouldBeVisibleWithoutLogin: true,
    },
    { name: faq.name, link: faq.link, shouldBeVisibleWithoutLogin: true },

    {
      name: sampleProfile.name,
      link: sampleProfile.link,
      shouldBeVisibleWithoutLogin: true,
    },
    {
      name: aboutUs.name,
      link: aboutUs.link,
      shouldBeVisibleWithoutLogin: true,
    },
    {
      name: summerTrips.name,
      link: summerTrips.link,
      shouldBeVisibleWithoutLogin: true,
    },
  ];

  const renderNavigationLinks = () =>
    navigationLinks.map(
      ({
        name,
        link,
        shouldBeVisibleWithoutLogin,
        shouldBeVisibleWithLogin,
        // eslint-disable-next-line array-callback-return,consistent-return
      }) => {
        if (shouldBeVisibleWithoutLogin) {
          return (
            <div className="nav__link nav-link" key={name}>
              <NavLink
                onClick={() => handleExpandChange(name)}
                className="inactive"
                activeClassName="active"
                to={link}
              >
                {name}
              </NavLink>
            </div>
          );
        }
        if (shouldBeVisibleWithLogin) {
          return (
            <div className="nav__link nav-link" key={name}>
              <NavLink
                onClick={() => handleExpandChange(name)}
                className="inactive"
                activeClassName="active"
                to={link}
              >
                {name}
              </NavLink>
            </div>
          );
        }
        if (!shouldBeVisibleWithoutLogin && isAuthorized && isProfileFinished) {
          return (
            <div className="nav__link nav-link" key={name}>
              <NavLink
                className="inactive"
                activeClassName="active"
                to={link}
                onClick={() => handleExpandChange(name)}
              >
                {name}
              </NavLink>
            </div>
          );
        }
      },
    );

  const history = useHistory();
  const handleLogoutButton = () => {
    setIsAuthorized(false);
    dispatch(
      logOut(history, { key: 'authorization', storage: window.localStorage }),
    );
  };

  return (
    <div className="Menu">
      <Navbar expanded={expanded} fixed="top" bg="light" expand="md">
        <Container>
          <NavLink className="nav__brand" exact to="/">
            <Navbar.Brand>
              <img
                className="navbar-brand"
                // src={brandLogo}
                src={brandLogo}
                alt="Play Sport Usa Logo"
              />
            </Navbar.Brand>
          </NavLink>
          <Navbar.Toggle
            onClick={handleMenuChange}
            aria-controls="basic-navbar-nav"
          />
          <Navbar.Collapse>
            <Nav className="ml-auto" style={{ alignItems: 'center' }}>
              {renderNavigationLinks()}
              {userRole === 'admin' && (
                <div className="nav__link nav-link" key={createPlayer.name}>
                  <NavLink
                    className="inactive"
                    activeClassName="active"
                    to={createPlayer.link}
                    onClick={handleExpandChange}
                  >
                    {createPlayer.name}
                  </NavLink>
                </div>
              )}
              {isAuthorized ? (
                <Profile
                  handleExpandChange={handleExpandChange}
                  handleLogoutButton={handleLogoutButton}
                  userName={userName}
                />
              ) : (
                <>
                  <div className="nav__link nav-link">
                    <NavLink
                      className="inactive"
                      activeClassName="active"
                      to={login.link}
                      onClick={handleExpandChange}
                    >
                      Login
                    </NavLink>
                  </div>
                  <div className="nav__link nav-link">
                    <NavLink
                      className="inactive"
                      activeClassName="active"
                      to={createAccount.link}
                      onClick={handleExpandChange}
                    >
                      {createAccount.name}
                    </NavLink>
                  </div>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Menu;
