/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import routes from './routes';
import Loader from '../shared/displayComponents/loader/Loader';

const GuardedRoute = ({
  component: Component,
  auth,
  isProfileFinished,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (auth && isProfileFinished) {
          return <Component {...props} />;
        }
        if (auth && isProfileFinished !== null && !isProfileFinished) {
          return <Redirect to={routes.finishProfile.link} />;
        }
        if (!auth) {
          return <Redirect to={routes.login.link} />;
        }
        return <Loader />;
      }}
    />
  );
};

export default GuardedRoute;
