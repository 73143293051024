import { combineReducers } from 'redux';

import playersReducer from './players/store/reducer';
import sharedReducer from './shared/store/reducer';
import authorizationReducer from './shared/store/authSlice';

const rootReducer = combineReducers({
  players: playersReducer,
  shared: sharedReducer,
  authorization: authorizationReducer,
});

export default rootReducer;
