import React from 'react';

import { Row } from 'react-bootstrap';

import AboutUsCarousel from './AboutUsCarousel';

import './Aboutus.scss';

const AboutUs = () => {
  return (
    <div className="about__us">
      <Row>
        <h4>How does our recruiting service work?</h4>
      </Row>
      <Row>
        <AboutUsCarousel />
      </Row>
      <Row className="about__us__explanation ">
        <h6>
          The profiles of our athletes will include: the direct contact button,
          videos, and all the necessary information to evaluate the specific
          athlete. Simply filter: the position, year, gender - to find the right
          fit!
        </h6>
      </Row>
      <Row className="about__us__questions">
        <h6>Who finds and evaluates the International recruits?</h6>
        <span>
          Kajetan Borecki - founder as well as his scouts recruit athletes on
          our platform. They search for elite prospects all over Poland/Europe
          and collaborate with coaches from Polish National Teams as well as
          Elite European clubs. The aim is to select the most promising athletes
          who have capabilities to compete in college sports on a highest level.
        </span>
      </Row>
      <Row className="about__us__questions">
        <h6>Does PLAYSPORTUSA places athletes with favoured schools?</h6>
        <span>
          No. PLAYSPORTUSA serves all subscribing coaches equally by publishing
          complete profiles on “Available Players”. Each subscriber gets an
          equal right to recruit a student-athlete. Each subscriber has access
          to contact information and can reach out to the athletes directly.
          Playsportusa wants every coach to be able to present his University
          and to be considered by our athletes.
        </span>
      </Row>
      <Row className="about__us__questions">
        <h6>At what levels are PLAYSPORTUSA athletes?</h6>
        <span>
          Mid-major DI or higher. Some can thrive for top-25 NCAA Division I.
          Most are better suited for mid-level D1, D2 NCAA teams.
        </span>
      </Row>
      <Row className="about__us__questions">
        <h6>Can I see a video of the recruits?</h6>
        <span>
          Yes, login in to PLAYSPORTUSA platform gives you access to the videos
          of each recruit. You also have access to all the necessary information
          to evaluate if it is a good fit.
        </span>
      </Row>
      <Row className="about__us__questions">
        <h6>Who pays for the Playsportusa service?</h6>
        <span>
          As of today Subscribing athletes pay for the service. Subscription for
          American coaches is completely FREE at this point.
        </span>
      </Row>
    </div>
  );
};

export default AboutUs;
