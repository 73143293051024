import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Form, FormControl } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import routes from '../../../routesDefinitions/routes';
import { logIn } from '../../store/authSlice';

import './Login.scss';

const Login = ({ setIsAuthorized }) => {
  const { handleSubmit, register } = useForm();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleHistory = useCallback(
    async values => {
      const isLoggedIn = await dispatch(logIn(values));
      if (isLoggedIn) {
        setIsAuthorized(true);
        history.push(routes.players.link);
      }
    },
    [dispatch, history, setIsAuthorized],
  );
  return (
    <div className="Login">
      <div className="Login__form">
        <Form className="form-signin" onSubmit={handleSubmit(handleHistory)}>
          <h1
            className="h3 mb-3 font-weight-normal"
            style={{ textAlign: 'center' }}
          >
            Sign in
          </h1>
          <div className="social-login">
            <button
              className="btn google-btn social-btn"
              type="button"
              onClick={e => {
                e.preventDefault();
                window.location.href =
                  'https://playsportusa.herokuapp.com/api/users/auth/google';
              }}
            >
              <span>
                <i className="fab fa-google-plus-g" />
                Sign in with Google+
              </span>
            </button>
          </div>
          <p style={{ textAlign: 'center' }}> OR </p>
          <FormControl
            ref={register}
            name="email"
            className="form-control"
            placeholder="Email address"
            required=""
          />
          <FormControl
            ref={register}
            name="password"
            className="form-control"
            placeholder="Password"
            required=""
            type="password"
          />
          <button className="btn btn-success btn-block" type="submit">
            <i className="fas fa-sign-in-alt" /> Sign in
          </button>
          <a href="/reset-password">Forgot password?</a>
          <hr />
          <p> Don&apos;t have an account!</p>
          <button
            className="btn btn-primary btn-block"
            type="button"
            onClick={() => history.push('/create-account')}
          >
            <i className="fas fa-user-plus" /> Sign up New Account
          </button>
        </Form>
      </div>
    </div>
  );
};

export default Login;
