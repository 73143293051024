import React, { useState, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import CreateAccountForm from '../shared/displayComponents/createAccount/CreateAccountForm';
import EditPassword from '../shared/displayComponents/createAccount/EditPassword';
import {
  createAccountSchema,
  editAccountSchema,
} from '../shared/displayComponents/createAccount/createAccountSchema';
import { editAccount } from '../shared/displayComponents/createAccount/store/actions';
import Loader from '../shared/displayComponents/loader/Loader';

import './finishProfile.scss';

const FinishProfile = () => {
  const { userEmail, user, isProfileFinished } = useSelector(
    ({ authorization }) => authorization,
  );
  const { isLoading } = useSelector(({ shared }) => shared);
  const { register, handleSubmit, errors, watch, getValues, reset } = useForm({
    validationSchema: !isProfileFinished
      ? createAccountSchema
      : editAccountSchema,
    defaultValues: {
      user,
    },
  });
  useEffect(() => {
    if (user) {
      const { password, ...newUser } = user;
      reset(newUser);
    }
  }, [reset, user]);

  const [showTerms, setShowTerms] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const finishProfileHandler = data => {
    dispatch(editAccount(userEmail, data, history, isProfileFinished));
  };
  const handleClose = () => setShowTerms(false);

  const watchShowAge = watch('teamDivision');

  return isLoading ? (
    <Loader />
  ) : (
    <div className="edit-container">
      <div className="profile-edit">
        <CreateAccountForm
          user={user}
          handleSubmit={handleSubmit}
          userEmail={userEmail}
          isProfileFinished={isProfileFinished}
          handleClose={handleClose}
          showTerms={showTerms}
          setShowTerms={setShowTerms}
          register={register}
          errors={errors}
          getValues={getValues}
          finishProfileHandler={finishProfileHandler}
          watchShowAge={watchShowAge}
        />
      </div>
      <div className="password-edit">
        <EditPassword />
      </div>
    </div>
  );
};
export default FinishProfile;
