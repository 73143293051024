export const reduceQueryString = routeQueryString => {
  const url = new URLSearchParams(routeQueryString).toString();
  return url && `?${url}`;
};

export const createQueryString = values => {
  const filteredValues = Object.entries(values).filter(
    ([, value]) => value !== 'Filter all',
  );
  return reduceQueryString(filteredValues);
};
