import * as Yup from 'yup';

const isScoreRegex = /^(\s*|\d+)$/;

const createPlayerSchema = Yup.object({
  firstName: Yup.string()
    .required()
    .label('First Name')
    .max(20),
  lastName: Yup.string()
    .required()
    .label('Last Name')
    .max(20),
  gender: Yup.string()
    .required()
    .label('Gender'),
  dateOfBirth: Yup.string()
    .required()
    .label('Date Of Birth'),
  email: Yup.string()
    .required()
    .label('Email')
    .email(),
  phoneNumber: Yup.string().label('Phone Number'),
  nationality: Yup.string()
    .required()
    .label('Nationality')
    .max(20),
  currentSchool: Yup.string()
    .label('Current School')
    .max(50),
  currentClub: Yup.string()
    .required()
    .label('Current Club')
    .max(50),
  graduationYear: Yup.string()
    .required()
    .label('Graduation Year')
    .max(50),
  highSchoolGpa: Yup.string()
    .label('High School GPA')
    .max(20),
  satScore: Yup.string().matches(isScoreRegex, 'Invalid Sat score.'),
  toeflScore: Yup.string().matches(isScoreRegex, 'Invalid Toefl score.'),
  height: Yup.string()
    .required()
    .label('Height')
    .max(20),
  weight: Yup.string()
    .required()
    .label('Weight')
    .max(20),
  sport: Yup.string()
    .required()
    .min(2, 'Choose correct sport discipline')
    .label('Sport'),
  youtubeUrl: Yup.array()
    .label('Youtube link')
    .max(100),
  accomplishments: Yup.array()
    .label('Accomplishments')
    .max(200),
  intendedMajor: Yup.string()
    .label('Intended Major')
    .max(60),
});

export default createPlayerSchema;
