import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Navbar, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';

import * as Yup from 'yup';

import { editPassword } from './store/actions';
import Loader from '../loader/Loader';

const editPasswordSchema = Yup.object({
  currentPassword: Yup.string()
    .max(15)
    .min(4)
    .required()
    .label('currentPassword'),
  newPassword: Yup.string()
    .max(15)
    .min(4)
    .required()
    .label('newPassword'),
  confirmNewPassword: Yup.string().oneOf(
    [Yup.ref('newPassword'), null],
    'Passwords must match',
  ),
});

const EditPassword = () => {
  const { isLoading } = useSelector(({ shared }) => shared);
  const { userEmail } = useSelector(({ authorization }) => authorization);

  const { register, handleSubmit, errors } = useForm({
    validationSchema: editPasswordSchema,
  });

  const dispatch = useDispatch();

  const changePassword = data => {
    dispatch(editPassword(userEmail, data));
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div className="createAccount__form" style={{ minHeight: 'auto' }}>
      <Navbar className="createAccount__title">
        <span>
          <i className="fas fa-user-circle" />
          Edit Password
        </span>
      </Navbar>
      <Form onSubmit={handleSubmit(changePassword)}>
        <Form.Group className="createAccount_section">
          <Form.Label>
            Current password
            <OverlayTrigger overlay={<Tooltip>Required!</Tooltip>}>
              <span className="section__req"> *</span>
            </OverlayTrigger>
          </Form.Label>
          <Form.Control
            className={errors.currentPassword && 'is-invalid'}
            ref={register}
            name="currentPassword"
            type="password"
          />
          <p className="validationError">{errors?.currentPassword?.message}</p>
          <Form.Label>
            New password
            <OverlayTrigger overlay={<Tooltip>Required!</Tooltip>}>
              <span className="section__req"> *</span>
            </OverlayTrigger>
          </Form.Label>
          <Form.Control
            className={errors.currentPassword && 'is-invalid'}
            ref={register}
            name="newPassword"
            type="password"
          />
          <p className="validationError">{errors?.newPassword?.message}</p>

          <Form.Label>
            Confirm new password
            <OverlayTrigger overlay={<Tooltip>Required!</Tooltip>}>
              <span className="section__req"> *</span>
            </OverlayTrigger>
          </Form.Label>
          <Form.Control
            type="password"
            className={errors.confirmPassword && 'is-invalid'}
            name="confirmNewPassword"
            ref={register}
          />
          <p className="validationError">
            {errors?.confirmNewPassword?.message}
          </p>
          <Button
            type="submit"
            className="CreateAccount__button"
            disabled={isLoading}
          >
            Edit
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
};

export default EditPassword;
