import React from 'react';

import './Loader.scss';

const Loader = () => (
  <div className="Loader__container">
    <div className="Loader">Loading ...</div>
  </div>
);
export default Loader;
