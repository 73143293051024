import jwtDecode from 'jwt-decode';

export const extractToken = ({
  key,
  headers,
  location = window.location,
  storage = window.localStorage,
}) => {
  const params = new URLSearchParams(location.search);
  if (params.has(key)) {
    return params.get(key);
  }

  if (headers && headers.has(key)) {
    return headers.get(key);
  }

  if (key in storage) {
    return storage.getItem(key);
  }

  return null;
};

export const storeToken = ({
  key,
  storage = window.localStorage,
  response,
}) => {
  const renewedToken = extractToken({
    key,
    storage,
    headers: new Headers(response.headers),
  });

  storage.setItem(key, renewedToken);
};

export const removeToken = ({ key, storage = window.localStorage }) => {
  storage.removeItem(key);
};

export const getUserEmailFromToken = async token => {
  const decodedToken = await jwtDecode(token);
  return decodedToken.email;
};
