import React from 'react';

import { NavDropdown } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import './Profile.scss';

const UserProfile = ({ handleLogoutButton, userName, handleExpandChange }) => {
  const history = useHistory();

  return (
    <NavDropdown
      title={
        <div
          className="userProfile__name"
          style={{
            display: 'inline-block',
            color: '#cc3d45',
            textTransform: 'uppercase',
          }}
        >
          <i className="fas fa-user-circle" />
          {userName}
        </div>
      }
    >
      <NavDropdown.Item
        onClick={() => {
          handleExpandChange();
          history.push(`/finish-profile`);
        }}
      >
        <i className="fas fa-user-cog" /> Profile
      </NavDropdown.Item>

      <NavDropdown.Divider />
      <NavDropdown.Item
        onClick={() => {
          handleExpandChange();
          handleLogoutButton();
        }}
      >
        <i className="fas fa-sign-out-alt" /> Logout
      </NavDropdown.Item>
    </NavDropdown>
  );
};

export default UserProfile;
