export const playerPhysicalFeature = [
  { key: 'Fairly Strong', section: 'Strength' },
  { key: 'Strong', section: 'Strength' },
  { key: 'Very Strong', section: 'Strength' },
  { key: 'Elite Strong', section: 'Strength' },
  { key: 'Mobile', section: 'Mobility' },
  { key: 'Good Mobility', section: 'Mobility' },
  { key: 'Very good Mobility', section: 'Mobility' },
  { key: 'Great Mobility', section: 'Mobility' },
  { key: 'Elite Mobility', section: 'Mobility' },
  { key: 'Fairly Coordinated', section: 'Coordination' },
  { key: 'Good Coordination', section: 'Coordination' },
  { key: 'Very good Coordination', section: 'Coordination' },
  { key: 'Elite Coordination', section: 'Coordination' },
  { key: 'Below Average', section: 'Foot Speed' },
  { key: 'Average', section: 'Foot Speed' },
  { key: 'Good Foot Speed', section: 'Foot Speed' },
  { key: 'Great Foot Speed', section: 'Foot Speed' },
  { key: 'Very good Foot Speed', section: 'Foot Speed' },
];
export const physicalStaticPlayer = [
  'Elite Strong',
  'Great Mobility',
  'Elite Coordination',
  'Great Foot Speed',
];
export const analysisStaticPlayer = [
  'High IQ',
  'Excellent technique',
  'Can hit thumb-down',
  'Tools hands very well',
  'Wide range of angles',
  'Great roll shot',
  'Gets the majority of sets on her team',
  'Highest p/ps on the team',
  'Top-spin server',
  'Team player with a great persona on the court',
];
export const playerAnalysisFeature = [
  'High IQ',
  'Calm persona',
  'Cross body hitter',
  'Fast arm',
  'Heavy arm',
  'Excellent technique',
  'Body line hitter',
  'Can hit thumb-down',
  'Big vertical',
  'Tools hands very well',
  'Fast of the ground',
  'Great in Transition',
  'Wide range of angles',
  'Great roll shot',
  'O/S sets',
  'Gets the majority of sets on her team',
  'Highest p/ps on the team',
  'Highest k/ps  ',
  'Consistent',
  'Jump Float serve',
  'Very good serve receive',
  'Great Serve Receive',
  'Top-spin server',
  'Team player with a great persona on the court',
  'Team captain',
  'Leader on the court',
  'Gets majority kills on her/his team',
  'Polish National Team member',
  'International experience',
  'Tips very well',
  'Go-to player',
  'Senior experience',
  'Dumps very well',
  'Offensive',
  'Pursues the ball very well',
  'Great technique',
  'Creates early angles very well passing',
  'Capable of running fast offense',
  'Quick arm',
  'Runs fast offense',
  'Runs Slides',
  'Very good defender',
  'Great defender',
  'Back row hitter',
  '6 rotation player',
  'Great blocker',
  'Closes the block very well',
  'Reads very well',
  'Great lateral movement',
  'Great swing blocker',
  'Great on the o/s ball',
  'Strong hands',
  'Shuffle step blocker',
  'Dribbles very well',
];
export const playerEnglishSkills = [
  'Average for the non-native speaker',
  'Below the non-native speaker',
  'Above the non-native speaker',
  'Far above the non-native speaker',
];
export const levelsOfPlay = ['NCAA D1', 'NCAA D2', 'NAIA', 'NJCAA'];
