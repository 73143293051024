import React from 'react';

import { Carousel, Image } from 'react-bootstrap';

import car1 from '../assets/car1.PNG';
import car2 from '../assets/car2.JPG';
import car3 from '../assets/car3.jpg';
import car4 from '../assets/car4.png';
import car5 from '../assets/car5.jpg';
import car6 from '../assets/car6.jpeg';
import car7 from '../assets/car7.jpg';
import car8 from '../assets/car8.jpg';
import car9 from '../assets/car9.jpeg';
import car10 from '../assets/car10.JPEG';
import car11 from '../assets/car11.JPG';
import car12 from '../assets/car12.JPG';
import car13 from '../assets/car13.JPG';
import car14 from '../assets/car14.jpg';
import car15 from '../assets/car15.jpg';
import car16 from '../assets/car16.jpg';
import car17 from '../assets/car17.jpg';

import './AboutUsCarousel.scss';

const AboutUsCarousel = () => {
  const nextIcon = <i className="fas fa-arrow-circle-right icon-right" />;
  const prevIcon = <i className="fas fa-arrow-circle-left icon-left" />;
  return (
    <Carousel
      nextIcon={nextIcon}
      prevIcon={prevIcon}
      interval="2000"
      variant="dark"
      className="carousel__container"
    >
      <Carousel.Item>
        <Image src={car1} alt="First slide" />
      </Carousel.Item>
      <Carousel.Item>
        <Image src={car2} alt="Second slide" />
      </Carousel.Item>
      <Carousel.Item>
        <Image src={car3} alt="Third slide" />
      </Carousel.Item>
      <Carousel.Item>
        <Image src={car4} alt="Fouth slide" />
      </Carousel.Item>
      <Carousel.Item>
        <Image src={car5} alt="Fifth slide" />
      </Carousel.Item>
      <Carousel.Item>
        <Image src={car6} alt="Sixth slide" />
      </Carousel.Item>
      <Carousel.Item>
        <Image src={car7} alt="Seventh slide" />
      </Carousel.Item>
      <Carousel.Item>
        <Image src={car8} alt="Eight slide" />
      </Carousel.Item>
      <Carousel.Item>
        <Image src={car9} alt="Ninth slide" />
      </Carousel.Item>
      <Carousel.Item>
        <Image src={car10} alt="Tenth slide" />
      </Carousel.Item>
      <Carousel.Item>
        <Image src={car11} alt="Eleventh slide" />
      </Carousel.Item>
      <Carousel.Item>
        <Image src={car12} alt="Twelfth slide" />
      </Carousel.Item>
      <Carousel.Item>
        <Image src={car13} alt="Thirteenth slide" />
      </Carousel.Item>
      <Carousel.Item>
        <Image src={car14} alt="Fourteenth slide" />
      </Carousel.Item>
      <Carousel.Item>
        <Image src={car15} alt="Fifteenth slide" />
      </Carousel.Item>
      <Carousel.Item>
        <Image src={car16} alt="Sixteenth slide" />
      </Carousel.Item>
      <Carousel.Item>
        <Image src={car17} alt="Seventeenth slide" />
      </Carousel.Item>
    </Carousel>
  );
};

export default AboutUsCarousel;
