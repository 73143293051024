import React from 'react';

import { Modal, Button } from 'react-bootstrap';
import './ModalTerms.scss';

const ModalTerms = ({ closeModal, showTerms }) => {
  return (
    <Modal show={showTerms} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Cookies and policy privacy</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>
          <b> 1. General Provisions</b> <br />
          {`The data administrator is Playsportusa- Kajetan Borecki with its
            registered office in Bielsko-Biała, NIP: 9372647352 REGON: 386230028.
          Data protection is carried out in accordance with the requirements of
          generally applicable law, and their storage takes place on secured
          servers. For the interpretation of terms, a glossary of the
          Regulations is used or as described in the Privacy Policy (if it
          results directly from the description). For the purpose of better
          reception of the Privacy Policy, the term "User" has been replaced by
          the terms "You", "Administrator" - "We". The term "GDPR" means
          Regulation (EU) 2016/679 of the European Parliament and of the Council
          of 27 April 2016 on the protection of individuals with regard to the
          processing of personal data and on the free movement of such data and
          repealing Directive 95/46 / EC. We respect the right to privacy and
          care for data security. For this purpose, among others, secure
          communication encryption protocol (SSL). Personal data provided in the
          form on the landing page are treated as confidential and are not
          visible to unauthorized persons.`}
          <br />
          <br />
          <b> 2. Personal data is processed</b> <br />
          <li>
            in accordance with the provisions on the protection of personal
            data,
          </li>
          <li>in accordance with the implemented Privacy Policy,</li>
          <li>
            to the extent and for the purpose necessary to establish, shape the
            content of the Agreement, change or terminate it, and correctly
            implement the Services provided electronically,
          </li>
          <li>
            to the extent and purpose necessary to fulfill legitimate interests
            (legitimate purposes), and the processing does not violate the
            rights and freedoms of the data subject.
          </li>
          <br />
          {`We reserve the right to process your data after termination of the
            Agreement or withdrawal of consent only to the extent that we need to
            seek any claims before a court or if national or EU regulations or
            international law oblige us to retain data. The Service Provider has
            the right to disclose User's personal data and other data to entities
            authorized under applicable law (e.g. law enforcement authorities).
            The deletion of personal data may occur as a result of withdrawing
            consent or filing a legally admissible objection to the processing of
            personal data. The Service Provider does not disclose personal data to
            entities other than those authorized under the applicable law. We have
            implemented pseudonymisation, data encryption and we have introduced
            access control, thanks to which we minimize the effects of a possible
            breach of data security. Personal data is processed only by persons
            authorized by us or processors with whom we work closely.`}
          <br />
          <br />
          <b> 3. Cookies </b> <br />
          The website www.playsportusa.pl uses cookies. These are small text
          files sent by the web server and stored by the browser computer
          software. When the browser reconnects to the site, the site recognizes
          the type of device the user connects to. Parameters allow reading the
          information contained therein only to the server that created them.
          Cookies therefore facilitate the use of previously visited websites.
          The information collected relates to the IP address, type of browser
          used, language, type of operating system, Internet service provider,
          time and date information, location and information sent to the
          website via the contact form. The collected data is used to monitor
          and check how users use our websites to improve the functioning of the
          website by providing more efficient and trouble-free navigation. We
          monitor user information using the Google Analytics tool, which
          records user behavior on the website. Cookies identify the user, which
          allows him to tailor the content of the website he uses to his needs.
          By remembering his preferences, he allows to tailor ads targeted to
          him. We use cookies to guarantee the highest standard of convenience
          for our website, and the collected data is used only inside [name] to
          optimize operations. We use the following cookies on our website:
          <li>
            {`"necessary" cookies, enabling the use of services available on the
            website, e.g. authentication cookies used for services that require
            authentication on the website`}
          </li>
          <li>
            cookies used to ensure security, e.g. used to detect fraud in the
            field of website authentication,
          </li>
          <li>
            {`"performance" cookies, enabling the collection of information on the
            use of website pages,`}
          </li>
          <li>
            {`
            "functional" cookies, enabling "remembering" the settings selected
            by the user and personalizing the user interface, eg in terms of the
            language or region of the user's origin, font size, website
            appearance, etc.,`}
          </li>
          <li>
            {`"advertising" cookies, enabling users to provide advertising content
            more tailored to their interests.`}
          </li>
          <br /> The User may at any time disable or restore the option of
          collecting cookies by changing the settings in the web browser. Cookie
          management instructions are available on the site
          http://www.allaboutcookies.org/manage-cookies Additional personal
          data, such as an e-mail address, are collected only in places where
          the user has expressly consented to this by completing the form. We
          save and use the above data only for the purposes necessary to perform
          the given function.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={closeModal} variant="secondary">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalTerms;
