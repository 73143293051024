import React from 'react';

import { Accordion, Row } from 'react-bootstrap';

import './Faq.scss';

const Faq = () => {
  return (
    <div className="faq">
      <Row>
        <h4>FAQ for coaches</h4>
      </Row>
      <Accordion>
        <Accordion.Toggle as="div" eventKey="0">
          <p className="faq__question">
            How do I get more details about the player?
          </p>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <div className="content">
            <p>
              Playsportusa includes all important details on the player’s
              profile. Additionally, you can find videos after clicking on the
              profile. If there’s any additional information you would like to
              know, please email: contact@playsportusa.pl to ask for the
              specific information.
            </p>
          </div>
        </Accordion.Collapse>
        <Accordion.Toggle as="div" eventKey="1">
          <p className="faq__question">
            How do I access player&apos;s profiles?
          </p>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="1">
          <div className="content">
            <p>
              Click on the link “Available Players” in the menu bar, you’ll see
              a list of all available athletes. To see the entire profile on a
              specific player, just click on the picture of the player and
              scroll down to see all relevant information and video material.
            </p>
          </div>
        </Accordion.Collapse>
        <Accordion.Toggle as="div" eventKey="2">
          <p className="faq__question">How do I contact a player?</p>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="2">
          <div className="content">
            <p>
              In order to directly contact a player you have to click on their
              profile and then click the button &quot;contact player&quot;. Your
              message will go directly to the e-mail of the specific player.
            </p>
          </div>
        </Accordion.Collapse>
        <Accordion.Toggle as="div" eventKey="4">
          <p className="faq__question">Is PLAYSPORTUSA a year-round service?</p>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="4">
          <div className="content">
            <p>
              Yes. We add new athletes every 1-2 weeks. We update their videos and add relevant information on a daily basis.
            </p>
          </div>
        </Accordion.Collapse>
      </Accordion>
    </div>
  );
};

export default Faq;
