import React, { useState } from 'react';

import {
  Form,
  FormControl,
  Navbar,
  Button,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';

import ModalTerms from './ModalTerms';

import gmailImage from '../../../assets/gmail.png';
import information from '../../../assets/information.png';

import './CreateAccountForm.scss';

const CreateAccountForm = ({
  user,
  register,
  handleSubmit,
  errors,
  createAccount,
  watchShowAge,
  showTerms,
  setShowTerms,
  handleClose,
  isProfileFinished,
  userEmail,
  finishProfileHandler,
}) => {
  const isCreatingAccount = isProfileFinished === null && !userEmail;
  const isFinishingProfile = isProfileFinished === false;
  const [isVerified, setVeryfied] = useState(false);

  const checkName = () => {
    if (isCreatingAccount) return 'Create Account';
    if (isFinishingProfile) return 'Finish Profile';
    return 'Edit Profile';
  };

  const onChange = value => {
    if (value) {
      setVeryfied(true);
    }
    return null;
  };

  return (
    <div className="createAccount__form">
      <Navbar className="createAccount__title">
        <span>
          <i className="fas fa-user-circle" />
          {checkName()}
        </span>
      </Navbar>
      <Form
        onSubmit={
          isProfileFinished === null && !user
            ? handleSubmit(createAccount)
            : handleSubmit(finishProfileHandler)
        }
      >
        <Form.Group className="createAccount_section">
          <Form.Label>
            University e-mail
            <OverlayTrigger overlay={<Tooltip>Required!</Tooltip>}>
              <span className="section__req"> *</span>
            </OverlayTrigger>
          </Form.Label>
          <Form.Control
            className={errors.universityEmail && 'is-invalid'}
            ref={register}
            name="universityEmail"
          />
          <p className="validationError">{errors?.universityEmail?.message}</p>

          <Form.Label>
            <img className="gmail-logo" src={gmailImage} alt="gmail logo" />
            <OverlayTrigger
              overlay={
                <Tooltip className="google-tooltip">
                  Provide your google e-mail to login with just one click!
                </Tooltip>
              }
            >
              <span className="section__req">
                <img
                  className="information-logo"
                  src={information}
                  alt="information alert logo"
                />
              </span>
            </OverlayTrigger>
          </Form.Label>
          <Form.Control
            className={errors.personalEmail && 'is-invalid'}
            ref={register}
            name="personalEmail"
            defaultValue={isCreatingAccount ? '' : userEmail}
          />
          <p className="validationError">{errors?.personalEmail?.message}</p>
          {(isCreatingAccount || isFinishingProfile) && (
            <>
              <Form.Label>
                Password
                <OverlayTrigger overlay={<Tooltip>Required!</Tooltip>}>
                  <span className="section__req"> *</span>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                className={errors.password && 'is-invalid'}
                ref={register}
                name="password"
                type="password"
              />
              <p className="validationError">{errors?.password?.message}</p>
              <Form.Label>
                Confirm Password
                <OverlayTrigger overlay={<Tooltip>Required!</Tooltip>}>
                  <span className="section__req"> *</span>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                type="password"
                className={errors.confirmPassword && 'is-invalid'}
                name="confirmPassword"
                ref={register}
              />
              <p className="validationError">
                {errors?.confirmPassword?.message}
              </p>
            </>
          )}
        </Form.Group>
        <Form.Group className="createAccount_section">
          <Form.Label>
            First name
            <OverlayTrigger overlay={<Tooltip>Required!</Tooltip>}>
              <span className="section__req"> *</span>
            </OverlayTrigger>
          </Form.Label>
          <Form.Control
            className={errors.firstName && 'is-invalid'}
            ref={register}
            name="firstName"
          />
          <p className="validationError">{errors?.firstName?.message}</p>

          <Form.Label>
            Last name
            <OverlayTrigger overlay={<Tooltip>Required!</Tooltip>}>
              <span className="section__req"> *</span>
            </OverlayTrigger>
          </Form.Label>
          <Form.Control
            className={errors.lastName && 'is-invalid'}
            ref={register}
            name="lastName"
          />
          <p className="validationError">{errors?.lastName?.message}</p>

          <Form.Label>
            Job title
            <OverlayTrigger overlay={<Tooltip>Required!</Tooltip>}>
              <span className="section__req"> *</span>
            </OverlayTrigger>
          </Form.Label>
          <Form.Control
            className={errors.jobTitle && 'is-invalid'}
            ref={register}
            name="jobTitle"
          />
          <p className="validationError">{errors?.jobTitle?.message}</p>

          <Form.Label>
            Cell phone
            <OverlayTrigger overlay={<Tooltip>Required!</Tooltip>}>
              <span className="section__req"> *</span>
            </OverlayTrigger>
          </Form.Label>
          <Form.Control
            className={errors.cellPhone && 'is-invalid'}
            ref={register}
            name="cellPhone"
          />
          <p className="validationError">{errors?.cellPhone?.message}</p>

          <Form.Label>WhatsApp</Form.Label>
          <Form.Control
            className={errors.whatsApp && 'is-invalid'}
            ref={register}
            name="whatsApp"
          />
          <p className="validationError">{errors?.whatsApp?.message}</p>
        </Form.Group>
        <Form.Group className="createAccount_section">
          <Form.Label>
            School name
            <OverlayTrigger overlay={<Tooltip>Required!</Tooltip>}>
              <span className="section__req"> *</span>
            </OverlayTrigger>
          </Form.Label>
          <Form.Control
            className={errors.schoolName && 'is-invalid'}
            ref={register}
            name="schoolName"
          />
          <p className="validationError">{errors?.schoolName?.message}</p>

          <Form.Label>
            Size of school
            <OverlayTrigger overlay={<Tooltip>Required!</Tooltip>}>
              <span className="section__req"> *</span>
            </OverlayTrigger>
          </Form.Label>
          <Form.Control
            className={errors.sizeOfSchool && 'is-invalid'}
            ref={register}
            name="sizeOfSchool"
          />
          <p className="validationError">{errors?.sizeOfSchool?.message}</p>

          <Form.Label>
            Your team&apos;s division
            <OverlayTrigger overlay={<Tooltip>Required!</Tooltip>}>
              <span className="section__req"> *</span>
            </OverlayTrigger>
          </Form.Label>

          <Form.Control
            as="select"
            className={errors.teamDivision && 'is-invalid'}
            ref={register}
            name="teamDivision"
          >
            <option value="-">-</option>
            <option value="NCAAD1">NCAA D1</option>
            <option value="NCAAD2">NCAA D2</option>
            <option value="NAIA">NAIA</option>
            <option value="NJCAA">NJCAA</option>
            <option value="Other">Other</option>
          </Form.Control>
          <p className="validationError">{errors?.teamDivision?.message}</p>
          {watchShowAge === 'NCAAD1' && (
            <>
              <Form.Label>RPI</Form.Label>
              <Form.Control
                as="select"
                className={errors.RPI && 'is-invalid'}
                ref={register}
                name="RPI"
              >
                <option>1-100</option>
                <option>101-200</option>
                <option>201+</option>
              </Form.Control>
              <p className="validationError">{errors?.rpi?.message}</p>
            </>
          )}
          <Form.Label>
            Name of the conference
            <OverlayTrigger overlay={<Tooltip>Required!</Tooltip>}>
              <span className="section__req"> *</span>
            </OverlayTrigger>
          </Form.Label>
          <Form.Control
            className={errors.conferenceName && 'is-invalid'}
            ref={register}
            name="conferenceName"
          />
          <p className="validationError">{errors?.conferenceName?.message}</p>
          <Form.Label>SAT</Form.Label>
          <Form.Control
            className={errors.SAT && 'is-invalid'}
            ref={register}
            name="SAT"
          />
          <p className="validationError">{errors?.SAT?.message}</p>
          <Form.Label>ITB Toefl</Form.Label>
          <Form.Control
            className={errors.ITB && 'is-invalid'}
            ref={register}
            name="ITB"
          />
          <p className="validationError">{errors?.ITB?.message}</p>

          <Form.Label>State</Form.Label>
          <FormControl
            className={errors.state && 'is-invalid'}
            ref={register}
            name="state"
          />
          <p className="validationError">{errors?.state?.message}</p>

          <Form.Label>City</Form.Label>
          <FormControl
            className={errors.city && 'is-invalid'}
            ref={register}
            name="city"
          />
          <p className="validationError">{errors?.city?.message}</p>
        </Form.Group>
        {(isCreatingAccount || isFinishingProfile) && (
          <Form.Group>
            <Form.Label
              onClick={() => setShowTerms(true)}
              className="CreateAccount__terms"
            >
              Terms of Service *
            </Form.Label>
            <Form.Check
              ref={register}
              type="checkbox"
              name="terms"
              className={errors.terms && 'is-invalid'}
            />
            <p className="validationError">{errors?.terms?.message}</p>
          </Form.Group>
        )}
        {showTerms && (
          <ModalTerms closeModal={handleClose} showTerms={showTerms} />
        )}
        <Button
          type="submit"
          className="CreateAccount__button"
          disabled={!isVerified}
        >
          <OverlayTrigger
            overlay={
              <Tooltip style={{ display: !isVerified ? 'block' : 'none' }}>
                Please verify that you are a human to create an account.
              </Tooltip>
            }
          >
            <p> {checkName()}</p>
          </OverlayTrigger>
        </Button>
        <ReCAPTCHA
          className="recaptcha"
          sitekey={process.env.REACT_APP_CAPTCHA_KEY}
          onChange={onChange}
        />
      </Form>
    </div>
  );
};

export default CreateAccountForm;
