import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Toast } from 'react-bootstrap';

import { toasterOff } from '../../store/actions';

import './Toastr.scss';

const Toastr = props => {
  const dispatch = useDispatch();
  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(toasterOff({ type: '', message: '' }));
    }, 3000);
    return () => clearTimeout(timer);
  }, [dispatch]);

  const toasterQuit = () => {
    dispatch(toasterOff({ type: '', message: '' }));
  };

  const { type, message } = props;
  return (
    <div className="Toastr">
      <Toast style={{}} onClick={toasterQuit}>
        <Toast.Header
          style={
            type === 'failed'
              ? { backgroundColor: 'red', color: 'black' }
              : { backgroundColor: 'green', color: 'black' }
          }
        >
          <strong className="mr-auto">
            {type === 'failed' ? 'Something went wrong!' : 'Success!'}
          </strong>
          <small>now</small>
        </Toast.Header>
        <Toast.Body style={{ textAlign: 'center' }}>{message}</Toast.Body>
      </Toast>
    </div>
  );
};
export default Toastr;
