import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Toastr from './shared/displayComponents/toast/Toastr';
import Players from './players/Players';
import Faq from './faq/Faq';
import AboutUs from './aboutUs/AboutUs';
import SampleProfile from './sampleProfile/SampleProfile';
import NotFound from './shared/displayComponents/notFound/NotFound';
import Footer from './shared/displayComponents/footer/Footer';
import Menu from './shared/displayComponents/menu/Menu';
import CreatePlayer from './players/createPlayer/CreatePlayer';
import PlayerPage from './players/playerPage/playerPage';
import Login from './shared/displayComponents/login/Login';
import FinishProfile from './home/finishProfile';
import ResetPassword from './home/ResetPassword';
import CreateAccount from './shared/displayComponents/createAccount/CreateAccount';
import EditPlayer from './players/editPlayer/EditPlayer';
import SummerTrips from './marketing/SummerTrips';
import routes from './routesDefinitions/routes';
import GuardedRoute from './routesDefinitions/GuardedRoute';
import { extractToken, getUserEmailFromToken } from './shared/utils/tokenUtils';
import {
  setUserEmail,
  checkIsProfileFinished,
  getUserRole,
  getUser,
} from './shared/store/authSlice';

import './App.scss';

const App = () => {
  const {
    players,
    faq,
    sampleProfile,
    aboutUs,
    home,
    createPlayer,
    login,
    finishProfile,
    createAccount,
    editPlayer,
    resetPassword,
    favorites,
    summerTrips,
  } = routes;
  const token = extractToken({ key: 'authorization' });
  const [isAuthorized, setIsAuthorized] = useState(Boolean(token));
  const { isVisible, message, type } = useSelector(({ shared }) => shared);
  const { isProfileFinished, userEmail, userRole, user } = useSelector(
    ({ authorization }) => authorization,
  );
  const dispatch = useDispatch();
  const showToaster = isVisible ? (
    <Toastr message={message} type={type} />
  ) : null;

  useEffect(() => {
    const decodeTokenAndSetUserEmail = async tokenToDecode => {
      const email = await getUserEmailFromToken(tokenToDecode);
      dispatch(setUserEmail(email));
    };
    if (isAuthorized && !userEmail) {
      decodeTokenAndSetUserEmail(token);
    }
  }, [dispatch, isAuthorized, token, userEmail]);

  if (isAuthorized && !window.localStorage.getItem('authorization')) {
    window.localStorage.setItem('authorization', token);
  }

  useEffect(() => {
    if (userEmail && isProfileFinished === null) {
      dispatch(getUser(userEmail));
      dispatch(checkIsProfileFinished({ email: userEmail }));
    }
  }, [dispatch, isProfileFinished, userEmail]);

  useEffect(() => {
    if (userEmail && isProfileFinished && !userRole) {
      dispatch(getUserRole({ email: userEmail }));
    }
  }, [dispatch, isProfileFinished, userEmail, userRole]);

  return (
    <div className="App">
      <Router>
        <Menu
          userName={user && user.firstName}
          setIsAuthorized={setIsAuthorized}
          isAuthorized={isAuthorized}
          isProfileFinished={isProfileFinished}
          userRole={userRole}
        />
        <div className="container">
          {showToaster}
          <Switch>
            <GuardedRoute
              exact
              path={finishProfile.link}
              component={FinishProfile}
              auth={isAuthorized}
              isProfileFinished
            />
            <GuardedRoute
              exact
              path={editPlayer.link}
              component={EditPlayer}
              auth={isAuthorized}
              isProfileFinished={isProfileFinished}
            />
            <GuardedRoute
              exact
              path={favorites.link}
              component={Players}
              auth={isAuthorized}
              isProfileFinished={isProfileFinished}
            />
            <GuardedRoute
              exact
              path={players.link}
              component={Players}
              auth={isAuthorized}
              isProfileFinished={isProfileFinished}
            />
            <GuardedRoute
              exact
              path={createPlayer.link}
              component={CreatePlayer}
              auth={isAuthorized}
              isProfileFinished={isProfileFinished}
            />
            <GuardedRoute
              exact
              path={`${players.link}/:id`}
              component={PlayerPage}
              auth={isAuthorized}
              isProfileFinished={isProfileFinished}
            />
            <Redirect exact from={home.link} to={players.link} />
            <Route
              exact
              path={createAccount.link}
              component={CreateAccount}
              auth={isAuthorized}
              isProfileFinished={isProfileFinished}
            />
            <Route
              exact
              path={aboutUs.link}
              component={AboutUs}
              auth={isAuthorized}
              isProfileFinished={isProfileFinished}
            />
            <Route
              exact
              path={summerTrips.link}
              component={SummerTrips}
              auth={isAuthorized}
              isProfileFinished={isProfileFinished}
            />
            <Route
              exact
              path={faq.link}
              component={Faq}
              auth={isAuthorized}
              isProfileFinished={isProfileFinished}
            />
            <Route
              exact
              path={sampleProfile.link}
              component={SampleProfile}
              auth={isAuthorized}
              isProfileFinished={isProfileFinished}
            />
            <Route
              exact
              path={faq.link}
              component={Faq}
              auth={isAuthorized}
              isProfileFinished={isProfileFinished}
            />
            <Route exact path={resetPassword.link} component={ResetPassword} />
            <Route exact path={login.link}>
              <Login setIsAuthorized={setIsAuthorized} />
            </Route>
            <Route component={NotFound} />
          </Switch>
        </div>
        <Footer />
      </Router>
    </div>
  );
};

export default App;
