import React from 'react';

import { Modal, Button } from 'react-bootstrap';

import './DeletePlayerModal.scss';

const ModalPop = ({ deletePlayerHandler, handleCloseModal, showModal }) => {
  return (
    <Modal show={showModal} onHide={handleCloseModal} backdrop="static">
      <Modal.Body className="modal__message">
        <i className="far fa-question-circle" /> Kajtuniu, are you sure you want
        to delete this Player?
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-warning"
          onClick={() => {
            deletePlayerHandler();
            handleCloseModal();
          }}
        >
          Delete
        </Button>
        <Button variant="outline-warning" onClick={handleCloseModal}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalPop;
