import axios from 'axios';

import { loaderOff, loaderOn, toasterOn } from '../../../store/actions';
import { checkIsProfileFinished, getUser } from '../../../store/authSlice';
import url from '../../../../constants/config';

export const addAccount = (user, history) => async dispatch => {
  try {
    dispatch(loaderOn());
    await axios.post(`${url}/users`, user);
    dispatch(loaderOff());
    dispatch(toasterOn({ type: 'success', message: 'User has been added!' }));
    history.push('/login');
  } catch (err) {
    dispatch(loaderOff());
    dispatch(
      toasterOn(
        err.response
          ? { message: err.response.data.message, type: 'failed' }
          : { message: err.message, type: 'failed' },
      ),
    );
  }
};
export const editAccount = (
  userEmail,
  user,
  history,
  isProfileFinished,
) => async dispatch => {
  try {
    const endpointUrl = isProfileFinished
      ? `${url}/users/${userEmail}`
      : `${url}/users/finish-profile/${userEmail}`;
    const token = window.localStorage.getItem('authorization');
    dispatch(loaderOn());
    await axios.put(endpointUrl, user, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(getUser(userEmail));
    dispatch(checkIsProfileFinished({ email: userEmail }));
    dispatch(loaderOff());
    dispatch(toasterOn({ type: 'success', message: 'User has been updated!' }));
  } catch (err) {
    dispatch(loaderOff());
    dispatch(
      toasterOn(
        err.response
          ? { message: err.response.data.message, type: 'failed' }
          : { message: err.message, type: 'failed' },
      ),
    );
  }
};

export const editPassword = (userEmail, data) => async dispatch => {
  try {
    const endpointUrl = `${url}/users/change-password/${userEmail}`;
    const token = window.localStorage.getItem('authorization');
    dispatch(loaderOn());
    await axios.put(endpointUrl, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch(loaderOff());
    dispatch(
      toasterOn({ type: 'success', message: 'Password has been changed!' }),
    );
  } catch (err) {
    dispatch(loaderOff());
    dispatch(
      toasterOn(
        err.response
          ? { message: err.response.data.message, type: 'failed' }
          : { message: err.message, type: 'failed' },
      ),
    );
  }
};
