import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import './SummerTrips.scss';
import brandLogo from '../assets/brandLogo.png';
import summerTrips from '../assets/summerTrips.png';

const SummerTrips = () => {
  const images = [
    'https://cdn.pixabay.com/photo/2019/08/22/08/12/bielsko-biala-4422883_1280.jpg',
    'https://slaskie.travel/Media/Default/.MainStorage/Article/bgx2erby.zr5/Enduro%20Trails%20BB2%20fot.%20Lucjusz%20Cykarski,%20Maciej%20Kopaniecki.jpg',
    'https://bi.im-g.pl/im/96/31/1c/z29563030AMP,Pogladowa-wizualizacja-ulic-3-Maja-i-Zamkowej--zap.jpg',
    'https://bauren.pl/wp-content/uploads/2008/09/Marlena_Faerber_Bauren_Hala_Sportowa_Bielsko038.jpg',
    'https://bauren.pl/wp-content/uploads/2008/09/Marlena_Faerber_Bauren_Hala_Sportowa_Bielsko024.jpg',
    'https://beskidzkieapartamenty.pl/wp-content/uploads/2018/01/beskidzkie-5.jpeg',
    'https://beskidzkieapartamenty.pl/wp-content/uploads/2018/01/beskidzkie-3.jpeg',
    'https://bielsko-biala.pl/sites/default/files/inline-images/Pampalini%20lowca%20zwierzat%20arch%20UM%20BB.jpg',

    'https://scontent.fktw5-1.fna.fbcdn.net/v/t39.30808-6/273050455_4840654936020930_3806015571863003042_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=52f669&_nc_ohc=a_WckhOzP4sAX9o_T5y&_nc_ht=scontent.fktw5-1.fna&oh=00_AfDdM9ncbk-9ddUJjc75BigwmLlMyqnKEIRJ3glkn3uZbg&oe=65202389',
    'https://www.whitemad.pl/wp-content/uploads/2023/03/Willa-Sixta-fot.-Krzysztof-Morcinek-materialy-BWA.jpg',
    'https://www.whitemad.pl/wp-content/uploads/2023/03/Uliczki-starowki.-Fot.-Lucjusz-Cykarski.jpg',
    'https://www.super-nowa.pl/kultura-cat/miniatury/CR_0_b0a9f11061d89b916de8b5dbc387fccd.jpg',
    'https://trasynawczasy.pl/36-large_default/szyndzielnia-i-klimczok-z-bielska-bialej-do-szczyrku.jpg',
    'https://polskazachwyca.pl/wp-content/uploads/2019/06/Zapora-Por%C4%85bka.-Fot.-Janericloebe-1392x928.jpg',
    'https://bi.im-g.pl/im/e1/75/1a/z27746273AMP,Cavatina-Hall-w-Bielsku-Bialej.jpg',
    'https://polskazachwyca.pl/wp-content/uploads/2018/06/g%C3%B3ra-%C5%BCar-beskid-ma%C5%82y-shutterstock_199305311.jpg',
    'https://polskazachwyca.pl/wp-content/uploads/2018/06/jezioro-mi%C4%99dzybrodzkie-mi%C4%99dzybrodzie-zywieckie-shutterstock_697113907.jpg',
    'https://polskazachwyca.pl/wp-content/uploads/2019/06/Dom-Tkacza-w-Bielsku-Bia%C5%82ej.-Fot.-Alicja-Migda%C5%82-Drost-Muzeum-w-Bielsku-Bia%C5%82ej-CC-BY-SA-2.5.jpg',
  ];

  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const openLightbox = index => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  return (
    <div className="summer-trips">
      <Container>
        <Row>
          <Col className="logo-container">
            <img className="logo-image" src={brandLogo} alt="Play Sport Usa" />
          </Col>
          <Col className="logo-container">
            <img className="logo-image" src={summerTrips} alt="Summer Trips" />
          </Col>
        </Row>
        <Row>
          <Col>
            <h1 style={{ paddingBottom: '30px' }}>
              Join the European College Summer Trip
            </h1>
            <p>
              Playsportusa is excited to invite you to an unforgettable European
              College Summer Trip! We have designed an exclusive experience
              where you can immerse yourself in the world of sports, culture,
              and adventure.
            </p>
            <p>
              Our team will take care of every detail, ensuring that your trip
              is seamless and enjoyable. From scheduling comfortable hotels to
              providing convenient transportation, organizing exciting matches,
              offering delicious meals, planning memorable trips, and
              accommodating any special requests you may have – we've got it all
              covered.
            </p>
            <p>
              Bielsko-Biała, nestled in the heart of Europe, will serve as your
              base. This charming city, with a population of 168,319 people, is
              known for its breathtaking natural beauty, surrounded by majestic
              mountains. Situated near the Czech and Slovakia borders, it offers
              easy access to some of the most iconic European destinations,
              including Krakow, Vienna, Prague, Bratislava, and Budapest.
              Bielsko-Biała is also a hub for professional volleyball, promising
              an elite sports experience.
            </p>
            <p>
              But don't just take our word for it. Hear from the participants of
              our previous International and National Teams who were thrilled
              with their experience in Bielsko-Biała. They loved the city's
              vibrant atmosphere, proximity to tourist attractions, and the
              opportunity to engage in top-tier volleyball matches.
            </p>
            <p>
              <strong>Explore Bielsko-Biała:</strong>
            </p>
            <div className="image-gallery">
              {images.map((image, index) => (
                <div key={index} className="image-item">
                  <img
                    src={image}
                    alt={`Bielsko-Biała ${index + 1}`}
                    onClick={() => openLightbox(index)}
                  />
                </div>
              ))}
            </div>
            <ul style={{ paddingTop: '50px' }}>
              <li>
                <a
                  href="https://youtu.be/em4CJIHrgjc"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Short Video (English Version)
                </a>
              </li>
              <li>
                <a
                  href="https://youtu.be/VyEKaBJsYhM"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  2nd Video
                </a>
              </li>
            </ul>
            <p>
              We can't wait to make this trip an unforgettable experience for
              you. Join us in creating lasting memories, improving your skills,
              and building lifelong friendships. Whether you're an athlete or a
              sports enthusiast, this journey promises excitement, growth, and
              fun.
            </p>
            <Button variant="primary">
              <a
                style={{ color: 'white' }}
                href="mailto:playsportusa@gmail.com"
              >
                Inquire Now
              </a>
            </Button>
          </Col>
        </Row>
      </Container>
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </div>
  );
};

export default SummerTrips;
