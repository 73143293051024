import React from 'react';

import './Footer.scss';

const Footer = () => {
  const socialLinks = [
    {
      name: 'fa fa-facebook-square',
      href: 'https://www.facebook.com/playsportusa1/',
    },
    {
      name: 'fa fa-instagram',
      href: 'https://www.instagram.com/playsportusa1/',
    },
    {
      name: 'fa fa-youtube-play',
      href: 'https://www.youtube.com/channel/UCChDfRK8jPXg0MBuyoBa10g',
    },
  ];

  const renderSocialLinks = () =>
    socialLinks.map(({ name, href }) => (
      <li className="list-inline-item" key={name}>
        <a
          className="list__redirection"
          href={href}
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className={name} />
        </a>
      </li>
    ));

  return (
    <footer className="Footer">
      <div className="footer__container">
        <div className="footer__social ">
          <ul className="social__list">{renderSocialLinks()}</ul>
        </div>
        <div className="footer__info">
          <span>
            PlaySportUSA {new Date().getFullYear()} © All Rights Reserved.
            Created by devQuest
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
