import axios from 'axios';

import { loaderOff, loaderOn, toasterOn } from '../../shared/store/actions';
import url from '../../constants/config';
import * as types from './types';

export const playersFetchDataSuccess = payload => {
  return {
    type: types.FETCH_ALL_PLAYERS,
    payload,
  };
};

export const playerFetchDataSuccess = player => {
  return {
    type: types.FETCH_PLAYER,
    payload: player.data,
  };
};

export const resetPagination = () => {
  return {
    type: types.RESET_PAGINATION,
  };
};

export const playerDeleteSuccess = playerId => {
  return {
    type: types.DELETE_PLAYER,
    payload: playerId,
  };
};
export const fetchPlayer = playerId => async dispatch => {
  try {
    dispatch(loaderOn());
    const token = window.localStorage.getItem('authorization');
    const player = await axios.get(`${url}/players/${playerId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(loaderOff());
    dispatch(playerFetchDataSuccess(player));
  } catch (err) {
    dispatch(loaderOff());
  }
};

export const fetchPlayers = (filteredUrl = '') => async dispatch => {
  try {
    dispatch(loaderOn());
    const token = window.localStorage.getItem('authorization');

    const response = await axios.get(`${url}/players${filteredUrl}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(loaderOff());
    dispatch(playersFetchDataSuccess(response.data));
  } catch (err) {
    dispatch(
      toasterOn(
        err.response
          ? { message: err.response.data, type: 'failed' }
          : { message: err.message, type: 'failed' },
      ),
    );
    dispatch(loaderOff());
  }
};

export const fetchFavs = (filteredUrl = '', userEmail) => async dispatch => {
  try {
    dispatch(loaderOn());
    const token = window.localStorage.getItem('authorization');
    const response = await axios.get(
      `${url}/players/${userEmail}/favourite${filteredUrl}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    dispatch(playersFetchDataSuccess(response.data));
    dispatch(loaderOff());
  } catch (err) {
    dispatch(
      toasterOn(
        err.response
          ? { message: err.response.data, type: 'failed' }
          : { message: err.message, type: 'failed' },
      ),
    );
    dispatch(loaderOff());
  }
};

export const deletePlayer = (playerId, history) => async dispatch => {
  try {
    dispatch(loaderOn());
    const token = window.localStorage.getItem('authorization');
    await axios.delete(`${url}/players/${playerId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(loaderOff());
    dispatch(playerDeleteSuccess(playerId));
    dispatch(
      toasterOn({ type: 'success', message: 'Player has been deleted!' }),
    );
    history.push(`/available-players`);
  } catch (err) {
    dispatch(toasterOn(err.message));
    dispatch(loaderOff());
  }
};

export const sendMessage = message => async dispatch => {
  try {
    dispatch(loaderOn());
    const token = window.localStorage.getItem('authorization');
    await axios.post(`${url}/email/send`, message, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(loaderOff());
    dispatch(toasterOn({ type: 'success', message: 'Message has been sent!' }));
  } catch (err) {
    dispatch(loaderOff());
    dispatch(
      toasterOn({
        type: 'failed',
        message:
          'Sending message failed! Try again or contact our support line at contactplaysportusa@gmail.com',
      }),
    );
  }
};

export const addFilters = filters => ({
  type: types.ADD_FILTER,
  payload: filters,
});

export const resetFilters = () => ({
  type: types.RESET_FILTERS,
});
