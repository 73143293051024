import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
import { storeToken, removeToken } from '../utils/tokenUtils';

import routes from '../../routesDefinitions/routes';

import url from '../../constants/config';
import { loaderOff, loaderOn, toasterOn } from './actions';

export const authorization = createSlice({
  name: 'authorization',
  initialState: {
    userEmail: null,
    userRole: null,
    isProfileFinished: null,
    user: {},
    favouritesPlayers: [],
  },
  reducers: {
    setUserEmail: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.userEmail = action.payload;
    },
    setUser: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.user = action.payload;
    },
    getUserFavourites: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.favouritesPlayers = action.payload;
    },
    setUserFavourites: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.favouritesPlayers = action.payload.data;
    },

    setIsProfileFinished: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.isProfileFinished = action.payload;
    },
    setUserRole: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.userRole = action.payload;
    },
    clearUser: state => {
      // eslint-disable-next-line no-param-reassign
      state.userEmail = null;
      // eslint-disable-next-line no-param-reassign
      state.userRole = null;
      // eslint-disable-next-line no-param-reassign
      state.isProfileFinished = null;
      // eslint-disable-next-line no-param-reassign
      state.user = {};
    },
  },
});

export const {
  setUserEmail,
  setIsProfileFinished,
  setUserRole,
  setUser,
  clearUser,
  getUserFavourites,
  setUserFavourites,
} = authorization.actions;

export const logIn = ({ email, password }) => async dispatch => {
  try {
    const result = await axios.post(`${url}/users/login`, {
      email,
      password,
    });
    const authToken = result.headers.authorization;
    const decodedToken = await jwtDecode(authToken);
    storeToken({
      key: 'authorization',
      storage: window.localStorage,
      response: result,
    });
    dispatch(setUserEmail(decodedToken.email));
    return true;
  } catch (error) {
    dispatch(
      toasterOn({
        message: error.response
          ? error.response.data.message
          : 'Login attempt failed',
        type: 'failed',
      }),
    );
  }
};
export const getFavouritesList = email => async dispatch => {
  try {
    const token = window.localStorage.getItem('authorization');
    const response = await axios.get(`${url}/users/favourite/${email}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(getUserFavourites(response.data.favouritePlayers));
  } catch (error) {
    dispatch(
      toasterOn({
        message: error.response ? error.response.data : 'Fetching user failed',
        type: 'failed',
      }),
    );
  }
};

export const addToFavourites = (userEmail, favoriteId) => async dispatch => {
  try {
    const token = window.localStorage.getItem('authorization');
    const data = await axios.post(
      `${url}/users/favourite/${userEmail}`,
      { id: favoriteId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    dispatch(setUserFavourites(data));
    dispatch(
      toasterOn({
        message: 'Added to favorites list!',
        type: 'success',
      }),
    );
  } catch (err) {
    dispatch(
      toasterOn(
        err.response
          ? { message: err.response.data.message, type: 'failed' }
          : { message: err.message, type: 'failed' },
      ),
    );
  }
};

export const deleteFromFavourites = (
  userEmail,
  favoriteId,
) => async dispatch => {
  try {
    const token = window.localStorage.getItem('authorization');
    const data = await axios.delete(
      `${url}/users/favourite/${userEmail}`,
      { data: { id: favoriteId } },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    dispatch(setUserFavourites(data));
    dispatch(
      toasterOn({
        message: 'Deleted from favorites list!',
        type: 'success',
      }),
    );
  } catch (err) {
    dispatch(
      toasterOn(
        err.response
          ? { message: err.response.data.message, type: 'failed' }
          : { message: err.message, type: 'failed' },
      ),
    );
  }
};

export const logOut = (history, { key, storage }) => dispatch => {
  removeToken({ key, storage });
  dispatch(clearUser());
  history.push(routes.login.link);
};

export const checkIsProfileFinished = ({ email }) => async dispatch => {
  try {
    const token = window.localStorage.getItem('authorization');
    const response = await axios.get(
      `${url}/users/is-profile-finished/${email}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    const { isProfileFinished } = response.data;
    dispatch(setIsProfileFinished(isProfileFinished));
  } catch (error) {
    dispatch(
      toasterOn({
        message: error.response ? error.response.data : 'Fetching user failed',
        type: 'failed',
      }),
    );
  }
};
export const getUser = email => async dispatch => {
  try {
    const token = window.localStorage.getItem('authorization');
    const response = await axios.get(`${url}/users/${email}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(setUser(response.data));
  } catch (error) {
    dispatch(
      toasterOn({
        message: error.response ? error.response.data : 'Fetching user failed',
        type: 'failed',
      }),
    );
  }
};

export const getUserRole = ({ email }) => async dispatch => {
  try {
    const token = window.localStorage.getItem('authorization');
    const response = await axios.get(`${url}/users/user-role/${email}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const { role } = response.data;
    dispatch(setUserRole(role));
  } catch (error) {
    dispatch(
      toasterOn({
        message: error.response ? error.response.data : 'Fetching user failed',
        type: 'failed',
      }),
    );
  }
};

export const resetPassword = (email, history) => async dispatch => {
  try {
    dispatch(loaderOn());
    await axios.put(`${url}/users/reset-password/${email}`);
    dispatch(
      toasterOn({
        message: 'Password reset successfully. Check your email!',
        type: 'success',
      }),
    );
    dispatch(loaderOff());
    history.push('/');
  } catch (error) {
    dispatch(loaderOff());
    dispatch(
      toasterOn({
        message: error.response
          ? error.response.data.message
          : 'Reseting password failed',
        type: 'failed',
      }),
    );
  }
};

export default authorization.reducer;
