import axios from 'axios';

import { loaderOff, loaderOn, toasterOn } from '../../../shared/store/actions';
import url from '../../../constants/config';

const addPlayer = ({ formValues, files }) => async dispatch => {
  try {
    dispatch(loaderOn());

    const formData = new FormData();
    formData.append('values', JSON.stringify(formValues));
    formData.append('file', files[0]);
    const token = window.localStorage.getItem('authorization');
    await axios.post(`${url}/players`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(loaderOff());
    dispatch(toasterOn({ type: 'success', message: 'Player has been added!' }));
  } catch (err) {
    dispatch(loaderOff());
    dispatch(
      toasterOn(
        err.response
          ? { message: err.response.data.message, type: 'failed' }
          : { message: err.message, type: 'failed' },
      ),
    );
  }
};

export default addPlayer;
