const routes = {
  home: {
    name: 'Home',
    link: '/',
  },
  favorites: {
    name: 'Favorite Players',
    link: '/favorite-players',
  },
  players: {
    name: 'Available Players',
    link: '/available-players',
  },
  faq: {
    name: 'FAQ',
    link: '/faq',
  },
  sampleProfile: {
    name: 'Sample Profile',
    link: '/sample-profile',
  },
  aboutUs: {
    name: 'About Us',
    link: '/about-us',
  },
  summerTrips: {
    name: 'College Summer Trips',
    link: '/college-summer-trips',
  },
  createPlayer: {
    name: 'Create Player',
    link: '/create-player',
  },
  login: {
    name: 'Login',
    link: '/login',
  },
  logout: {
    name: 'Logout',
    link: '/login',
  },
  finishProfile: {
    name: 'finishProfile',
    link: '/finish-profile',
  },
  createAccount: {
    name: 'Create Account',
    link: '/create-account',
  },
  editPlayer: {
    name: 'Edit Player',
    link: '/edit/:id',
  },
  resetPassword: {
    name: 'Reset password',
    link: '/reset-password',
  },
};

export default routes;
