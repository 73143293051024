import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { createAccountSchema } from './createAccountSchema';
import CreateAccountForm from './CreateAccountForm';
import { addAccount } from './store/actions';
import Loader from '../loader/Loader';

const CreateAccount = () => {
  const { isLoading } = useSelector(({ shared }) => shared);
  const { isProfileFinished, userEmail } = useSelector(
    ({ authorization }) => authorization,
  );

  const { register, handleSubmit, errors, watch, getValues } = useForm({
    validationSchema: createAccountSchema,
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const [showTerms, setShowTerms] = useState(false);

  const createAccount = data => {
    dispatch(addAccount(data, history));
  };

  const handleClose = () => setShowTerms(false);

  const watchShowAge = watch('teamDivision');

  return isLoading ? (
    <Loader />
  ) : (
    <CreateAccountForm
      userEmail={userEmail}
      isProfileFinished={isProfileFinished}
      handleClose={handleClose}
      showTerms={showTerms}
      setShowTerms={setShowTerms}
      register={register}
      handleSubmit={handleSubmit}
      errors={errors}
      getValues={getValues}
      createAccount={createAccount}
      watchShowAge={watchShowAge}
    />
  );
};

export default CreateAccount;
