import React from 'react';

import PlayerPage from '../players/playerPage/playerPage';
import exampleProfile from '../assets/exampleProfile.jpg';

import {
  physicalStaticPlayer,
  analysisStaticPlayer,
} from '../players/createPlayer/selectObjects';

const staticPlayer = {
  firstName: 'Patrycja',
  lastName: 'Witt',
  gender: 'Female',
  dateOfBirth: '2001-02-02',
  email: 'patrycja @op.pl',
  phoneNumber: '+4865624253',
  nationality: 'Poland',
  currentSchool: 'SMS Szczyrk',
  currentClub: 'LTS Legionovia',
  graduationYear: '2021',
  highSchoolGpa: '3.5',
  satScore: '1100',
  toeflScore: '85',
  height: "6'3''",
  weight: '53',
  position: ['OH'],
  sport: 'Voleyball',
  physicals: physicalStaticPlayer,
  englishSkills: 'Far above the non-native speaker',
  levelOfPlay: ['NCAA D1'],
  analysis: analysisStaticPlayer,
  image: exampleProfile,
  youtubeUrl: ['https://www.youtube.com/embed/BrQSc8E2Flc'],
  accomplishments: [
    '1st place at the Cadet Polish Championships',
    '1st place at the Cadet European Championships',
  ],
  intendedMajor: 'Sport management',
  additionalComments: 'In the past she played as an outside hitter. ',
  duolingoScore: '122',
  attackReach: '3.20',
};

const SampleProfile = () => <PlayerPage staticPlayer={staticPlayer} />;

export default SampleProfile;
