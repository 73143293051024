import React from 'react';

import { Button, Navbar, Form } from 'react-bootstrap';
import { filters } from './filters';

import './FilterBar.scss';

const FilterBar = ({
  handleSubmit,
  SubmitFilter,
  ResetForm,
  register,
  watch,
}) => {
  const { gender, years, sports } = filters;
  const { volleyball, basketball, soccer } = filters.positions;
  const selectedSport = watch('sport');

  const getOptions = () => {
    switch (selectedSport) {
      case 'Volleyball':
        return volleyball;
      case 'Basketball':
        return basketball;
      case 'Soccer':
        return soccer;
      default:
        return [];
    }
  };

  const renderOptions = () => {
    const data = getOptions();
    return data.map(position => (
      <option value={position} key={position}>
        {position}
      </option>
    ));
  };

  return (
    <Form onSubmit={handleSubmit(SubmitFilter)}>
      <Navbar className="filter__bar" bg="light" expand="lg">
        <Navbar.Brand className="filter__brand">Filter</Navbar.Brand>
        <Form.Group>
          <Form.Label>Sport</Form.Label>
          <Form.Control
            ref={register}
            className="form__control"
            as="select"
            name="sport"
          >
            {sports.map(sport => (
              <option value={sport} key={sport}>
                {sport}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>Gender</Form.Label>
          <Form.Control
            ref={register}
            className="form__control"
            as="select"
            name="gender"
          >
            {gender.map(gen => (
              <option value={gen} key={gen}>
                {gen}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>Available from</Form.Label>
          <Form.Control
            ref={register}
            className="form__control"
            as="select"
            name="graduationYear"
          >
            {years.map(year => (
              <option value={year} key={year}>
                {year}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        {renderOptions().length ? (
          <Form.Group>
            <Form.Label>Position</Form.Label>
            <Form.Control
              ref={register}
              className="form__control"
              as="select"
              name="position"
            >
              {renderOptions()}
            </Form.Control>
          </Form.Group>
        ) : null}
        <Button type="submit">Filter</Button>
        <Button type="reset" variant="secondary" onClick={ResetForm}>
          Reset
        </Button>
      </Navbar>
    </Form>
  );
};

export default FilterBar;
