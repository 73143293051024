import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { resetPassword } from '../shared/store/authSlice';
import Loader from '../shared/displayComponents/loader/Loader';

const ResetPassword = () => {
  const { register, handleSubmit, errors } = useForm({
    validationSchema: Yup.object({
      email: Yup.string()
        .required()
        .email()
        .label('Email'),
    }),
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoading } = useSelector(({ shared }) => shared);

  const onSubmit = ({ email }) => {
    dispatch(resetPassword(email, history));
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div style={{ minHeight: '80vh' }}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Label style={{ marginBottom: '20px' }}>
          Reset your password
        </Form.Label>
        <Form.Control
          className={`${errors.email && 'is-invalid'}`}
          style={{ maxWidth: '50%', marginBottom: '20px' }}
          as="input"
          name="email"
          placeholder="Enter your email"
          ref={register}
        />
        <p style={{ color: '#dc3545' }}>{errors?.email?.message}</p>
        <Button className="" type="submit">
          <span>Reset</span>
        </Button>
      </Form>
    </div>
  );
};

export default ResetPassword;
